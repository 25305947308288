/* Table of Contents
/* ------------------------------------------------------------

This is a development CSS file which is built to a minified
production stylesheet in assets/built/screen.css

1.  Global Styles
2.  Layout
3.  Special Templates
4.  Site Header
5.  Site Navigation
6.  Post Feed
7.  Single Post
  7.1. Post Byline
  7.2. Members Subscribe Form
  7.4. Related Posts
  7.5. Koenig Styles
  7.6  Comments
8.  Author Template
9.  Error Template
11. Site Footer
12. Dark Mode

*/

/* 1. Global - Set up the things
/* ---------------------------------------------------------- */

/* Import CSS reset and base styles */
@import "global.css";

:root {

    /* Colours */
    --color-green: #a4d037;
    --color-yellow: #fecd35;
    --color-red: #f05230;
    --color-darkgrey: #15171A;
    --color-midgrey: #738a94;
    --color-lightgrey: #f1f1f1;
    --color-secondary-text: #979797;
    --color-border: #e1e1e1;
    --color-wash: #e5eff5;
    --color-darkmode: #151719;

    /*
    An accent color is also set by Ghost itself in
    Ghost Admin > Settings > Brand

    --ghost-accent-color: {value};

    You can use this variable throughout your styles
     */

     --ghost-accent-color: #ff0095;

    /* Fonts */
    --font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --font-serif: Georgia, Times, serif;
    --font-mono: Menlo, Courier, monospace;

}


/* 2. Layout - Page building blocks
/* ---------------------------------------------------------- */

.viewport {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-content {
    flex-grow: 1;
}

/* Full width page blocks */
.outer {
    position: relative;
    padding: 0 max(4vmin, 20px);
}

/* Centered content container blocks */
.inner {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
}


/* 4. Site Header
/* ---------------------------------------------------------- */

.site-header {
    position: relative;
    color: #fff;
    background: var(--ghost-accent-color);
}

.site-header-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.site-header-content {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    padding-top: calc(19vmin + 44px);
    padding-bottom: 19vmin;
    text-align: center;
    color: var(--color-darkgrey);
}

.has-cover .site-header-content {
    min-height: 560px;
    background-color: var(--ghost-accent-color);
    color: #fff;
}

.site-header-content.left-aligned {
    padding-bottom: 0;
    text-align: left;
}

.has-cover .site-header-content.left-aligned {
    align-items: flex-end;
    padding-bottom: max(4vmin, 32px);
}

.site-header-content.no-content {
    padding-top: 0;
    padding-bottom: 2vmin;
}

.site-header-inner {
    position: relative;
}

.site-header-content.left-aligned .site-header-inner {
    align-items: flex-start;
}

.site-logo {
    flex-shrink: 0;
    margin: 0 auto;
    max-height: 120px;
}

.site-header-content.left-aligned .site-logo {
    margin-right: auto;
    margin-left: 0;
    max-height: 96px;
}

.site-title {
    z-index: 10;
    margin: 0;
    padding: 0;
    font-size: 5rem;
    font-weight: 800;
}

.has-serif-title .site-title {
    font-family: var(--font-serif);
}

.has-cover .site-title {
    font-size: 6rem;
}

.site-header-content.left-aligned .site-title {
    font-size: 4.4rem;
}

.has-cover .site-header-content.left-aligned .site-title {
    font-size: 4.6rem;
}

.site-description {
    display: inline-block;
    z-index: 10;
    max-width: 960px;
    font-size: 6rem;
    font-weight: 700;
    line-height: 1.1;
}

:is(.site-logo, .site-title) + .site-description {
    max-width: 640px;
    margin-top: 16px;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.4;
}

.site-logo + .site-description {
    margin-top: 20px;
}

.site-title + .site-description {
    color: var(--color-secondary-text);
}

.has-cover .site-description {
    letter-spacing: -0.005em;
    color: #fff;
}

.has-cover :is(.site-logo, .site-title) + .site-description {
    font-size: 2.4rem;
}

.has-cover .site-header-content.left-aligned :is(.site-logo, .site-title) + .site-description {
    font-size: 2.2rem;
}

@media (min-width: 992px) {
    .is-head-stacked.has-cover .site-header-content {
        padding-top: calc(19vmin + 120px);
    }
}

@media (max-width: 991px) {
    .site-header-content {
        padding-top: calc(19vmin + 32px);
    }
}

@media (max-width: 767px) {
    .has-cover .site-header-content {
        min-height: 240px;
    }
    .site-header-inner {
        gap: 16px;
    }
    .site-logo {
        max-width: 60%;
    }
    .site-title {
        font-size: 3.4rem !important;
    }
    .site-description {
        font-size: 2.8rem !important;
    }
    .site-logo + .site-description {
        margin-top: 12px !important;
    }
    .site-title + .site-description {
        margin-top: 4px !important;
    }
}


/* 5. Site Navigation
/* ---------------------------------------------------------- */

.gh-head {
    height: 88px;
    font-size: 1.6rem;
    line-height: 1.3em;
    background-color: #fff;
}

.has-cover:not(.home-template) .gh-head {
    background-color: var(--ghost-accent-color);
    color: #fff;
}

:is(.home-template, .paged:not(.tag-template):not(.author-template)).has-cover .gh-head {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
    background-color: transparent;
    color: #fff;
}

.gh-head a {
    text-decoration: none;
}

.gh-head-inner {
    display: grid;
    column-gap: 40px;
    grid-template-columns: auto 1fr auto;
    grid-auto-flow: row dense;
    align-items: center;
    height: 100%;
}

/* Header styles
/* ---------------------------------------------------------- */

.is-head-left-logo .gh-head-inner {
    grid-template-columns: auto 1fr auto;
}

.is-head-left-logo.home-template .gh-head-logo {
    display: none;
}

.is-head-left-logo.home-template .gh-head-menu {
    margin-left: -40px;
}

@media (min-width: 992px) {
    .is-head-left-logo .gh-head-menu {
        margin-right: 32px;
        margin-left: 16px;
    }
}

.is-head-middle-logo .gh-head-inner {
    grid-template-columns: 1fr auto 1fr;
}

.is-head-middle-logo .gh-head-brand {
    grid-column-start: 2;
}

@media (min-width: 992px) {
    .is-head-middle-logo .gh-head-menu {
        margin-right: 64px;
    }
}

.is-head-stacked .gh-head {
    height: auto;
}

.is-head-stacked .gh-head-inner {
    grid-template-columns: 1fr auto 1fr;
}

.is-head-stacked .gh-head-brand {
    grid-row-start: 1;
    grid-column-start: 2;
}

@media (min-width: 992px) {
    .is-head-stacked .gh-head-inner {
        padding: 0;
    }

    .is-head-stacked .gh-head-brand {
        position: relative;
        display: flex;
        align-items: center;
        height: 80px;
    }

    .is-head-stacked .gh-head-menu {
        grid-row-start: 2;
        grid-column: 1 / 4;
        justify-content: center;
        height: 56px;
        margin: 0 48px;
    }

    .is-head-stacked .gh-head-menu::before,
    .is-head-stacked .gh-head-menu::after {
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        height: 1px;
        content: "";
        background-color: var(--color-lightgrey);
    }

    .is-head-stacked.has-cover .gh-head-menu::before,
    .is-head-stacked.has-cover .gh-head-menu::after {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .is-head-stacked .gh-head-menu::after {
        top: 136px;
    }

    .is-head-stacked .gh-head-actions {
        grid-row-start: 1;
        grid-column: 1 / 4;
        justify-content: space-between;
    }
}

/* Brand
/* ---------------------------------------------------------- */

.gh-head-brand {
    display: flex;
    align-items: center;
    height: 40px;
    word-break: break-all;
}

.gh-head-logo {
    display: block;
    font-weight: 800;
    font-size: 2.6rem;
    letter-spacing: -0.02em;
    color: inherit;
    white-space: nowrap;
}

.gh-head-logo.no-image {
    margin-top: -2px;
}

.has-cover .gh-head-logo {
    color: #fff;
}

.gh-head-logo img {
    max-height: 40px;
}


/* Primary Navigation
/* ---------------------------------------------------------- */

.gh-head-menu {
    display: flex;
    align-items: center;
    margin-top: 1px;
    font-weight: 500;
}

.gh-head-menu .nav {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.gh-head-menu .nav li {
    margin: 0;
    padding: 0;
}

.gh-head-menu .nav a {
    display: inline-block;
    line-height: 1.7;
    color: inherit;
}

.gh-head-menu .nav a:hover {
    opacity: 0.9;
}

.gh-head-menu .nav-more-toggle {
    position: relative;
    width: 30px;
    height: 30px;
    margin: 0 -6px;
    padding: 0;
    font-size: inherit;
    background-color: transparent;
    text-transform: inherit;
}

.gh-head-menu .nav-more-toggle svg {
    width: 24px;
    height: 24px;
}

@media (min-width: 992px) {
    body:not(.is-dropdown-loaded) .gh-head-menu .nav > li {
        opacity: 0;
    }
}


/* Dropdown
/* ---------------------------------------------------------- */

.gh-dropdown {
    position: absolute;
    top: 100%;
    right: -16px;
    z-index: 90;
    width: 200px;
    padding: 12px 0;
    margin-top: 24px;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 7px 20px -5px rgba(0, 0, 0, 0.15);
    transform: translate3d(0, 6px, 0);
    transition: opacity 0.3s, transform 0.2s;
}

.is-head-middle-logo .gh-dropdown {
    right: auto;
    left: -24px;
}

.is-dropdown-mega .gh-dropdown {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    column-gap: 40px;
    min-width: 320px;
    padding: 20px 32px;
}

.is-dropdown-open .gh-dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.gh-head-menu .gh-dropdown li a {
    display: block;
    padding: 6px 20px;
    color: #15171a;
}

.is-dropdown-mega .gh-dropdown li a {
    padding: 8px 0;
}


/* Secondary Navigation
/* ---------------------------------------------------------- */

.gh-social {
    display: flex;
    align-items: center;
    gap: 20px;
}

.gh-social-link {
    line-height: 0;
    color: inherit;
}

.gh-social-link:hover {
    opacity: 0.9;
}

.gh-social-link svg {
    width: 18px;
    height: 18px;
}

.gh-head-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    list-style: none;
    text-align: right;
}

.gh-head-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    height: 44px;
    font-weight: 600;
    letter-spacing: -0.005em;
    font-size: 1.6rem;
    border-radius: 48px;
    color: #fff;
    background: var(--ghost-accent-color);
}

.has-cover .gh-head-button {
    color: var(--color-darkgrey);
    background: #fff;
}


/* Search
/* ---------------------------------------------------------- */

.gh-search {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: none;
}

.gh-search:hover {
    opacity: 0.9;
}

.gh-head-brand .gh-search {
    margin-right: 8px;
}

.gh-head-actions .gh-search {
    margin-right: -4px;
}

@media (max-width: 991px) {
    .gh-head-actions .gh-search {
        display: none;
    }
}

@media (min-width: 992px) {
    .gh-head-brand .gh-search {
        display: none;
    }
}


/* Mobile Menu Trigger
/* ---------------------------------------------------------- */

.gh-burger {
    position: relative;
    display: none;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: -3px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    appearance: none;
}

.gh-burger::before,
.gh-burger::after {
    position: absolute;
    left: 3px;
    width: 24px;
    height: 1px;
    content: "";
    background-color: var(--color-darkgrey);
    transition: all 0.2s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
}

.has-cover .gh-burger::before,
.has-cover .gh-burger::after {
    background-color: #fff;
}

.gh-burger::before {
    top: 11px;
}

.gh-burger::after {
    bottom: 11px;
}

.gh-head-open .gh-burger::before {
    top: 15px;
    transform: rotate(45deg);
}

.gh-head-open .gh-burger::after {
    bottom: 14px;
    transform: rotate(-45deg);
}


/* Mobile Menu
/* ---------------------------------------------------------- */
/* IDs needed to ensure sufficient specificity */

@media (max-width: 991px) {
    #gh-head {
        height: 64px;
    }

    #gh-head .gh-head-inner {
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 1fr;
        gap: 48px;
    }

    #gh-head .gh-head-brand {
        display: grid;
        grid-template-columns: 1fr auto auto;
        grid-column-start: 1;
        align-items: center;
        height: 64px;
    }

    #gh-head .gh-head-logo {
        font-size: 2.2rem;
    }

    #gh-head .gh-head-brand .gh-search {
        margin-left: -6px;
    }

    #gh-head .gh-burger {
        display: block;
    }

    #gh-head .gh-head-menu,
    #gh-head .gh-head-actions {
        position: fixed;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
    }

    #gh-head .gh-head-menu {
        margin: 0;
        transition: none;
        transform: translateY(0);
    }

    #gh-head .nav {
        gap: 16px;
        align-items: center;
        line-height: 1.4;
    }

    #gh-head .nav a {
        font-size: 2.6rem;
        font-weight: 600;
        text-transform: none;
    }

    #gh-head .nav li {
        opacity: 0;
        transform: translateY(-4px);
    }

    #gh-head .gh-head-button {
        width: 100%;
        font-size: 1.8rem;
        text-transform: none;
        opacity: 0;
        transform: translateY(8px);
    }

    .gh-head-open #gh-head {
        position: fixed;
        right: 0;
        left: 0;
        z-index: 3999999;
        height: 100vh;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .gh-head-open.has-cover #gh-head,
    .gh-head-open.has-cover #gh-head .gh-head-actions {
        background-color: var(--ghost-accent-color);
    }

    .gh-head-open #gh-head .gh-head-menu,
    .gh-head-open #gh-head .gh-head-actions {
        position: static;
        visibility: visible;
        opacity: 1;
    }

    .gh-head-open #gh-head .nav {
        display: flex;
        flex-direction: column;
    }

    .gh-head-open #gh-head .nav li {
        opacity: 1;
        transition: transform 0.2s, opacity 0.2s;
        transform: translateY(0);
    }

    .gh-head-open #gh-head .gh-head-actions {
        position: sticky;
        right: 0;
        bottom: 0;
        left: 0;
        display: inline-flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        padding: max(4vmin, 20px) 0;
        background-color: #fff;
    }

    .gh-head-open #gh-head .gh-head-button {
        opacity: 1;
        transition: transform 0.4s, opacity 0.4s;
        transition-delay: 0.2s;
        transform: translateY(0);
    }
}


/* 6. Post Feed
/* ---------------------------------------------------------- */

.post-feed {
    position: relative;
    display: grid;
    gap: 4.8vmin 4vmin;
    grid-template-columns: repeat(6, 1fr);
    padding: max(4.8vmin, 36px) 0 0;
}

:is(.tag-template, .author-template) .post-feed {
    margin-top: 4vmin;
}

@media (max-width: 991px) {
    .post-feed {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 767px) {
    .post-feed {
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }
}

.post-card {
    position: relative;
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    background-size: cover;
    word-break: break-word;
}

.post-card-image-link {
    position: relative;
    overflow: hidden;
    display: block;
    margin-bottom: 32px;
}

.post-card-image-link::after {
    content: "";
    display: block;
    padding-bottom: 55%;
}

.post-card[class*="post-access-"] .post-card-image-link::after {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
}

.post-card.keep-ratio[class*="post-access-"] .post-card-image-link::after {
    position: absolute;
    inset: 0;
    padding-bottom: 0;
}

.post-card.keep-ratio:not(.post-card-large):not(.post-card-full) .post-card-image-link::after {
    padding-bottom: 0;
}

.post-card-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: var(--color-lightgrey) no-repeat center center;
    object-fit: cover;
}

.post-card.keep-ratio:not(.post-card-large):not(.post-card-full) .post-card-image {
    position: static;
}

.post-card-access {
    position: absolute;
    inset: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
}

.post-card-content-link {
    position: relative;
    display: block;
    color: var(--color-darkgrey);
}

.post-card-content-link:hover {
    text-decoration: none;
}

.post-feed .no-image .post-card-content-link {
    padding: 0;
}

.no-image .post-card-header {
    margin-top: 0;
}

.post-card-tags {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0 0 10px;
    color: var(--color-secondary-text);
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: -0.005em;
    line-height: 1;
}

.post-card-featured {
    position: relative;
    display: flex;
    align-items: center;
    gap: 3px;
    padding-left: 18px;
    color: var(--ghost-accent-color);
}

.post-card-featured svg {
    position: absolute;
    left: 0;
}

.post-card-title {
    margin: 0;
    font-size: 2.6rem;
    font-weight: 800;
    line-height: 1.2;
}

.post-card-content-link:hover .post-card-title {
    opacity: 0.9;
}

.no-image .post-card-title {
    margin-top: 0;
}

.has-serif-title .post-card-title {
    font-family: var(--font-serif);
    letter-spacing: -0.005em;
}

.post-card-title svg {
    margin-top: -3px;
    margin-left: -1px;
}

.post-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.post-card-excerpt {
    display: -webkit-box;
    overflow-y: hidden;
    margin-top: 12px;
    max-width: 720px;
    font-size: 1.6rem;
    line-height: 1.5;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.has-sans-body .post-card-excerpt {
    font-family: var(--font-sans);
}

.post-card:not(.post-card-large):not(.post-card-full):not(.dynamic):not(.no-image) .post-card-excerpt {
    -webkit-line-clamp: 2;
}

:is(.tag-template, .author-template) .post-card-excerpt {
    margin-top: 6px;
}

.post-card-meta {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 12px;
    padding: 0;
    font-size: 1.3rem;
    color: var(--color-secondary-text);
}

.post-card-meta > * {
    display: flex;
    align-items: center;
    gap: 6px;
}

.post-card-meta > * + *:not(script)::before {
    width: 2px;
    height: 2px;
    content: "";
    background-color: var(--color-secondary-text);
    border-radius: 50%;
}

.post-card-meta .sep {
    margin: 0 4px;
}

.author-profile-image {
    display: block;
    width: 100%;
    height: 100%;
    background: color-mod(var(--color-lightgrey) l(+10%));
    border-radius: 100%;

    object-fit: cover;
}

.author-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 4px;
    padding: 0;
    list-style: none;
}

.author-list-item {
    position: relative;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
}


/* Special Styling for home page grid (below):

The first post in the list is styled to be bigger than the others and take over
the full width of the grid to give it more emphasis. Wrapped in a media query to
make sure this only happens on large viewports / desktop-ish devices.

*/

@media (min-width: 1001px) {
    .post-card-large {
        grid-column: span 6;
        display: grid;
        grid-gap: 4vmin;
        grid-template-columns: repeat(3, 1fr);
        border-top: 0;
    }

    .post-card-large:not(.no-image) .post-card-header {
        margin-top: 0;
    }

    .post-card-large .post-card-image-link {
        position: relative;
        grid-column: span 2;
        margin-bottom: 0;
    }

    .post-card-large .post-card-content {
        grid-column: span 1;
    }

    .post-card-large.no-image .post-card-content {
        grid-column: span 2;
    }

    .post-card-large .post-card-image {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .post-card-large .post-card-tags {
        margin-bottom: 12px;
    }

    .post-card-large .post-card-title {
        margin-top: 0;
        font-size: 4.4rem;
        line-height: 1.05;
    }

    .post-card-large .post-card-excerpt {
        margin-top: 16px;
    }

    .post-card-full {
        grid-column: span 6;
    }

    .post-card-full .post-card-image-link {
        margin-bottom: 40px;
    }

    .post-card-full .post-card-tags {
        margin-bottom: 14px;
    }

    .post-card-full .post-card-title {
        font-size: 6.4rem;
        line-height: 0.95;
    }

    .post-card-full .post-card-excerpt {
        margin-top: 20px;
        font-size: 1.8rem;
    }

    .post-card-large + .post-card-large:nth-child(even) {
        margin: 32px 0;
    }

    .post-card-large + .post-card-large:nth-child(even) .post-card-content {
        order: -1;
    }

    .post-card.dynamic {
        grid-column: span 3;
    }

    .post-card.dynamic .post-card-title {
        font-size: 3rem;
    }
}

/* Pagination
/* ---------------------------------------------------------- */

.pagination {
    display: none;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    margin-top: 8vmin;
}

html.no-infinite-scroll .pagination {
    display: grid;
}

.pagination a {
    font-size: 1.7rem;
    font-weight: 600;
}

.pagination .page-number {
    grid-column-start: 2;
    color: var(--color-secondary-text);
}

.pagination .older-posts {
    grid-column-start: 3;
    text-align: right;
}

@media (max-width: 767px) {
    .pagination .page-number {
        display: none;
    }
}


/* 7. Single Post
/* ---------------------------------------------------------- */

.article {
    padding: max(8vmin, 40px) 0 max(8vmin, 64px);
    word-break: break-word;
}

.page-template .article {
    padding-top: max(12vmin, 64px);
}

.article-header {
    padding: 0 0 max(6.4vmin, 40px) 0;
}

.page-template .article-header {
    padding-bottom: max(3.2vmin, 28px);
}

.article-tag {
    margin-bottom: 16px;
    font-size: 1.6rem;
}

.article-tag a {
    color: var(--color-secondary-text);
}

.article-title {
    margin-bottom: 0;
    font-size: clamp(3.2rem, 5vw, 5.2rem);
    font-weight: 800;
    line-height: 1.05;
    color: var(--color-darkgrey);
}

.has-serif-title .article-title {
    font-family: var(--font-serif);
}

.article-excerpt {
    margin-top: 20px;
    max-width: 720px;
    font-size: 2rem;
    line-height: 1.45;
    color: var(--color-darkgrey);
}

.gh-canvas .article-image {
    grid-column: wide-start / wide-end;
    width: 100%;
    margin: max(6.4vmin, 40px) 0 0;
}

.image-full .article-image {
    grid-column: full-start / full-end;
}

.image-small .article-image {
    grid-column: main-start / main-end;
}

.gh-canvas .article-image img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media (max-width: 767px) {
    .article-excerpt {
        margin-top: 14px;
        font-size: 1.7rem;
    }
}

/* -------- */

/* Content grid
/* ---------------------------------------------------------- */

/* Canvas creates a multi-column, centered grid which the post
is laid out on top of. Canvas just defines the grid, we don't
use it for applying any other styles. */

.gh-canvas {
    display: grid;
    grid-template-columns:
        [full-start]
        minmax(max(4vmin, 20px), auto)
            [wide-start]
            minmax(auto, 240px)
                [main-start]
                    min(720px, calc(100% - max(8vmin, 40px)))
                [main-end]
            minmax(auto, 240px)
            [wide-end]
        minmax(max(4vmin, 20px), auto)
        [full-end]
    ;
}

.gh-canvas > * {
    grid-column: main-start / main-end;
}

.kg-width-wide {
    grid-column: wide-start / wide-end;
}

.kg-width-full {
    grid-column: full-start / full-end;
}

.kg-width-full img {
    width: 100%;
}


/* Content
/* ---------------------------------------------------------- */

/* Content refers to styling all page and post content that is
created within the Ghost editor. The main content handles
headings, text, images and lists. We deal with cards lower down. */

/* Default vertical spacing */
.gh-content > * + * {
    margin-top: max(3.2vmin, 24px);
    margin-bottom: 0;
}

/* [id] represents all headings h1-h6, reset all margins */
.gh-content > [id] {
    margin: 0;
    color: var(--color-darkgrey);
}

.has-serif-title .gh-content > [id] {
    font-family: var(--font-serif);
}

/* Add back a top margin to all headings, unless a heading
is the very first element in the post content */
.gh-content > [id]:not(:first-child) {
    margin: 2em 0 0;
}

/* Add a small margin between a heading and anything after it */
.gh-content > [id] + * {
    margin-top: 1.5rem !important;
}

/* A larger margin before/after HRs and blockquotes */
.gh-content > hr,
.gh-content > blockquote {
    position: relative;
    margin-top: max(4.8vmin, 32px);
}
.gh-content > hr + *,
.gh-content > blockquote + * {
    margin-top: max(4.8vmin, 32px) !important;
}

/* Now the content typography styles */
.gh-content a {
    color: var(--ghost-accent-color);
    text-decoration: underline;
    word-break: break-word;
}

.gh-content > blockquote:not([class]),
.gh-content > ol,
.gh-content > ul,
.gh-content > dl,
.gh-content > p {
    font-family: var(--font-serif);
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.6em;
}

.gh-content .kg-callout-card .kg-callout-text,
.gh-content .kg-toggle-card .kg-toggle-content > ol,
.gh-content .kg-toggle-card .kg-toggle-content > ul,
.gh-content .kg-toggle-card .kg-toggle-content > p {
    font-family: var(--font-serif);
    font-weight: 400;
    font-size: 1.9rem;
    line-height: 1.6em;
}

.gh-content .kg-product-card .kg-product-card-description > p,
.gh-content .kg-product-card .kg-product-card-description > ol,
.gh-content .kg-product-card .kg-product-card-description > ul {
    font-size: 1.7rem;
    line-height: 1.6em;
}

.gh-content .kg-callout-card .kg-callout-emoji {
    font-size: 2.1rem;
    line-height: 1.4em;
}

.gh-content .kg-toggle-card .kg-toggle-heading-text {
    font-size: 2.0rem;
}

.has-sans-body .gh-content > blockquote,
.has-sans-body .gh-content > ol,
.has-sans-body .gh-content > ul,
.has-sans-body .gh-content > dl,
.has-sans-body .gh-content > p,
.has-sans-body .gh-content .kg-callout-card .kg-callout-text,
.has-sans-body .gh-content .kg-toggle-card .kg-toggle-content > ol,
.has-sans-body .gh-content .kg-toggle-card .kg-toggle-content > ul,
.has-sans-body .gh-content .kg-toggle-card .kg-toggle-content > p {
    font-family: var(--font-sans);
}

.gh-content > ul,
.gh-content > ol,
.gh-content > dl,
.gh-content .kg-toggle-card .kg-toggle-content > ol,
.gh-content .kg-toggle-card .kg-toggle-content > ul,
.gh-content .kg-product-card .kg-product-card-description > ol,
.gh-content .kg-product-card .kg-product-card-description > ul {
    padding-left: 1.9em;
}

.gh-content > blockquote:not([class]) {
    position: relative;
    font-style: italic;
    padding: 0;
}

.gh-content > blockquote:not([class])::before {
    content: "";
    position: absolute;
    left: -1.5em;
    top: 0;
    bottom: 0;
    width: 0.3rem;
    background: var(--ghost-accent-color);
}

.gh-content :not(pre) > code {
    vertical-align: middle;
    padding: 0.15em 0.4em 0.15em;
    border: #e1eaef 1px solid;
    font-weight: 400 !important;
    font-size: 0.9em;
    line-height: 1em;
    color: #15171A;
    background: #f0f6f9;
    border-radius: 0.25em;
}

.gh-content pre {
    overflow: auto;
    padding: 16px 20px;
    color: var(--color-wash);
    font-size: 1.4rem;
    line-height: 1.5em;
    background: var(--color-darkgrey);
    border-radius: 5px;
    box-shadow: 0 2px 6px -2px rgba(0,0,0,.1), 0 0 1px rgba(0,0,0,.4);
}

@media (max-width: 650px) {
    .gh-content > blockquote:not([class]),
    .gh-content > ol,
    .gh-content > ul,
    .gh-content > dl,
    .gh-content > p,
    .gh-content .kg-callout-card .kg-callout-text,
    .gh-content .kg-toggle-card .kg-toggle-content > ol,
    .gh-content .kg-toggle-card .kg-toggle-content > ul,
    .gh-content .kg-toggle-card .kg-toggle-content > p {
        font-size: 1.8rem;
    }

    .gh-content .kg-product-card .kg-product-card-description > p,
    .gh-content .kg-product-card .kg-product-card-description > ol,
    .gh-content .kg-product-card .kg-product-card-description > ul {
        font-size: 1.6rem;
    }

    .gh-content blockquote:not([class])::before {
        left: min(-4vmin, -20px);
    }
}


/* Cards
/* ---------------------------------------------------------- */

/* Cards are dynamic blocks of content which appear within Ghost
posts, for example: embedded videos, tweets, galleries, or
specially styled bookmark links. We add extra styling here to
make sure they look good, and are given a bit of extra spacing. */

/* Add extra margin before/after any cards,
except for when immediately preceeded by a heading */
.gh-content :not(.kg-card):not([id]) + .kg-card {
    margin-top: 6vmin;
    margin-bottom: 0;
}
.gh-content .kg-card + :not(.kg-card) {
    margin-top: 6vmin;
    margin-bottom: 0;
}

/* This keeps small embeds centered */
.kg-embed-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* This keeps small iamges centered */
.kg-image-card img {
    margin: auto;
}

.has-serif-title .kg-toggle-card .kg-toggle-heading-text {
    font-family: var(--font-serif);
}

.gh-content .kg-callout-card-accent a {
    text-decoration: underline;
}

.kg-blockquote-alt {
    font-family: var(--font-serif);
    color: var(--color-midgrey);
}

.has-sans-body .kg-blockquote-alt {
    font-family: var(--font-sans);
}

.kg-card.kg-header-card.kg-style-dark {
    background: var(--color-darkgrey);
}

.kg-header-card.kg-style-light h2.kg-header-card-header {
    color: color-mod(var(--color-darkgrey) l(-5%));
}

.has-serif-title .kg-header-card h2.kg-header-card-header {
    font-family: var(--font-serif);
}


/* Captions */
figcaption {
    padding: 1.5rem 1.5rem 0;
    text-align: center;
    color: rgba(0,0,0,0.5);
    font-size: 1.3rem;
    line-height: 1.4em;
}
figcaption strong {
    color: rgba(0,0,0,0.8);
}
figcaption a {
    text-decoration: underline;
}


/* Highly specific styles for traditional Instagram embeds */
iframe.instagram-media {
    margin-top: 6vmin !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 0 !important;
}

iframe.instagram-media + script + :not([id]) {
    margin-top: 6vmin;
}


/* Card captions
/* ---------------------------------------------------------- */

.kg-width-full.kg-card-hascaption {
    display: grid;
    grid-template-columns: inherit;
}

.kg-width-wide.kg-card-hascaption img {
    grid-column: wide-start / wide-end;
}
.kg-width-full.kg-card-hascaption img {
    grid-column: 1 / -1;
}

.kg-width-full.kg-card-hascaption figcaption {
    grid-column: main-start / main-end;
}

.article-comments {
    margin: 6vmin 0 0 0;
}

/* -----old------ */

.footnotes-sep {
    margin-bottom: 30px;
}

.footnotes {
    font-size: 1.5rem;
}

.footnotes p {
    margin: 0;
}

.footnote-backref {
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none !important;
    box-shadow: none !important;
}

/* Tables */
.gh-content table:not(.gist table) {
    display: inline-block;
    overflow-x: auto;
    max-width: 100%;
    width: auto;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: var(--font-sans);
    font-size: 1.6rem;
    white-space: nowrap;
    vertical-align: top;
    -webkit-overflow-scrolling: touch;
    background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center, radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
    background-attachment: scroll, scroll;
    background-size: 10px 100%, 10px 100%;
    background-repeat: no-repeat;
}

.gh-content table:not(.gist table) td:first-child {
    background-image: linear-gradient(to right, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-size: 20px 100%;
    background-repeat: no-repeat;
}

.gh-content table:not(.gist table) td:last-child {
    background-image: linear-gradient(to left, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-position: 100% 0;
    background-size: 20px 100%;
    background-repeat: no-repeat;
}

.gh-content table:not(.gist table) th {
    color: var(--color-darkgrey);
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
    background-color: color-mod(var(--color-wash) l(+4%));
}

.gh-content table:not(.gist table) th,
.gh-content table:not(.gist table) td {
    padding: 6px 12px;
    border: color-mod(var(--color-wash) l(-1%) s(-5%)) 1px solid;
}


/* 7.1. Post Byline
/* ---------------------------------------------------------- */

.article-byline {
    display: flex;
    justify-content: space-between;
    margin: min(24px, 5.6vmin) 0 0;
}

.article-byline-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.article-byline-content .author-list {
    justify-content: flex-start;
    padding: 0 14px 0 0;
}

.article-byline-meta {
    color: var(--color-secondary-text);
    font-size: 1.4rem;
    line-height: 1.2em;
}

.article-byline-meta .author-name {
    margin: 0 0 6px;
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: 0;
}

.article-byline-meta .bull {
    display: inline-block;
    margin: 0 2px;
}

.author-avatar {
    display: block;
    overflow: hidden;
    margin: 0 -4px;
    width: min(56px, 13.6vmin);
    height: min(56px, 13.6vmin);
    border: #fff 2px solid;
    border-radius: 50%;
    background-color: var(--color-border);
}

.page-template .article-title {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .article-byline-content .author-list {
        padding-right: 12px;
    }
    .article-byline-meta .author-name {
        margin-bottom: 4px;
    }
}


/* 7.3. Subscribe
/* ---------------------------------------------------------- */

.footer-cta {
    position: relative;
    text-align: center;
}

.footer-cta-title {
    margin: 0 0 min(24px, 6.4vmin);
    font-size: clamp(2.6rem, 5vw, 3.8rem);
    font-weight: 800;
}

.has-serif-title .footer-cta-title {
    font-family: var(--font-serif);
}

.footer-cta-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    padding: 5px 5px 5px 15px;
    font-size: 1.7rem;
    color: var(--color-secondary-text);
    background: #fff;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    transition: border-color 0.2s;
}

.footer-cta-button:hover {
    border-color: color-mod(var(--color-border) l(-12%));
}

.footer-cta-button span {
    display: inline-block;
    padding: 9px 15px;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: -0.005em;
    background: var(--ghost-accent-color);
    border-radius: 6px;
}


/* 7.4. Read more
/* ---------------------------------------------------------- */

.read-more-wrap {
    margin-top: 2.4vmin;
}

.footer-cta + .read-more-wrap {
    margin-top: max(12vmin, 72px);
}

.read-more {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 4vmin;
}

.read-more .post-card-tags {
    display: none;
}

@media (max-width: 1000px) {
    .read-more {
        grid-template-columns: repeat(4, 1fr);
    }
    .read-more .post-card:nth-child(3) {
        display: none;
    }
}

@media (max-width: 700px) {
    .read-more {
        grid-template-columns: repeat(2, 1fr);
    }
    .read-more .post-card:nth-child(2) {
        display: none;
    }
}

/* 7.6. Comments
/* ---------------------------------------------------------- */
.comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0 44px;
}


.comments-head {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 100%;
    max-width: 720px;
}

.comments h2 {
    width: 100%;
    max-width: 720px;
    font-weight: 800;
    font-size: 3.4rem;
}

.comments .comment-count {
    color: var(--color-midgrey);
    font-weight: 600;
    white-space: nowrap;
}

.comments #ghost-comments-root {
    width: 100%;
    max-width: 720px;
}


/* 8. Author Template
/* ---------------------------------------------------------- */

.author-profile-pic {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin: 0 0 2rem;
    background: color-mod(var(--color-lightgrey) l(+10%));
    border-radius: 50%;
}

.author-profile-footer {
    margin-top: 16px;
}

.author-profile-location {
    font-weight: 700;
}

.author-profile-meta {
    display: flex;
    gap: 10px;
}

.author-profile-social-link {
    font-size: 1.3rem;
    color: var(--color-secondary-text);
}

.author-profile-social-link:hover {
    color: var(--color-darkgrey);
}

.author-profile-social-link svg {
    width: 16px;
    height: 16px;
}

@media (min-width: 1001px) {
    .author-template .post-card-large .post-card-content:only-child {
        grid-column: span 2;
        max-width: 640px;
    }
}


/* 8. Tag Template
/* ---------------------------------------------------------- */

.tag-template .post-card-large .post-card-image-link {
    grid-column: 2 / span 2;
    order: 2;
}

.tag-template .post-card-large .post-card-content {
    order: 1;
}

@media (min-width: 1001px) {
    .tag-template .post-card-large .post-card-content:only-child {
        grid-column: span 2;
        max-width: 640px;
    }
}

/* 9. Error Template
/* ---------------------------------------------------------- */

.error-content {
    padding: 14vw 4vw 2vw;
}

.error-message {
    padding-bottom: 10vw;
    text-align: center;
}

.error-code {
    margin: 0;
    font-size: 12vw;
    line-height: 1em;
    letter-spacing: -5px;
}

.error-description {
    margin: 0;
    color: var(--color-secondary-text);
    font-size: 3.2rem;
    line-height: 1.3em;
    letter-spacing: -0.005em;
    font-weight: 400;
}

.error-link {
    display: inline-block;
    margin-top: 5px;
}

@media (min-width: 940px) {
    .error-content .post-card {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
}

@media (max-width: 800px) {
    .error-content {
        padding-top: 24vw;
    }
    .error-code {
        font-size: 11.2rem;
    }
    .error-message {
        padding-bottom: 16vw;
    }
    .error-description {
        margin: 5px 0 0 0;
        font-size: 1.8rem;
    }
}

@media (max-width: 500px) {
    .error-content {
        padding-top: 28vw;
    }
    .error-message {
        padding-bottom: 14vw;
    }
}


/* 11. Site Footer
/* ---------------------------------------------------------- */

.site-footer {
    position: relative;
    margin: max(12vmin, 64px) 0 0 0;
    padding-top: 48px;
    padding-bottom: 140px;
    color: #fff;
    background: color-mod(var(--color-darkgrey) l(-5%));
}

.site-footer .inner {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: auto 1fr auto;
    color: rgba(255,255,255,0.7);
    font-size: 1.3rem;
}

.site-footer .copyright a {
    color: #fff;
    letter-spacing: -0.015em;
    font-weight: 500;
}

.site-footer a {
    color: rgba(255,255,255,0.7);
}

.site-footer a:hover {
    color: rgba(255,255,255,1);
    text-decoration: none;
}

.site-footer-nav ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
}

.site-footer-nav li {
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin: 0;
    line-height: 2em;
}

.site-footer-nav a {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
}

.site-footer-nav li:not(:first-child) a:before {
    content: "";
    display: block;
    width: 2px;
    height: 2px;
    margin: 0 10px 0 0;
    background: #fff;
    border-radius: 100%;
}

@media (max-width: 767px) {
    .site-footer .inner {
        max-width: 500px;
        grid-template-columns: 1fr;
        grid-gap: 0;
        text-align: center;
    }
    .site-footer .copyright,
    .site-footer .copyright a {
        color: #fff;
        font-size: 1.5rem;
    }
    .site-footer .copyright {
        margin-bottom: 16px;
    }
}


/* 12. Dark Mode
/* ---------------------------------------------------------- */

html.dark-mode body {
    color: rgba(255, 255, 255, 0.75);
    background: var(--color-darkmode);
}

html.dark-mode img {
    opacity: 0.9;
}

html.dark-mode kbd {
    background: color-mod(var(--color-darkmode) l(+5%));
}

html.dark-mode figcaption a {
    color: #fff;
}

html.dark-mode .gh-head {
    background: var(--color-darkmode);
    color: #fff;
}

html.dark-mode .gh-burger-box {
    color: #fff;
}

html.dark-mode .site-header-content {
    color: #fff;
}

html.dark-mode .post-card-image {
    background: var(--color-darkmode);
}

html.dark-mode :is(.post-card-tags, .post-card-meta, .article-tag a, .byline-meta-content) {
    color: color-mod(var(--color-secondary-text) l(-22%));
}

html.dark-mode .post-card-featured {
    color: #fff;
}

html.dark-mode .post-card-title {
    color: #fff;
}

html.dark-mode .post-card-excerpt {
    color: var(--color-secondary-text);
}

html.dark-mode .author-profile-location {
    color: #fff;
}

html.dark-mode .author-profile-social-link:hover {
    color: #fff;
}

html.dark-mode .article-title {
    color: #fff;
}

html.dark-mode .article-excerpt {
    color: var(--color-secondary-text);
}

html.dark-mode .post-full-image {
    background-color: color-mod(var(--color-darkmode) l(+8%));
}

html.dark-mode .author-avatar {
    border-color: var(--color-darkmode);
    background-color: color-mod(var(--color-darkmode) l(+8%));
}

html.dark-mode .author-profile-image {
    opacity: 1;
}

html.dark-mode .author-profile-image path {
    fill: var(--color-darkmode);
}

html.dark-mode .article-byline-meta .author-name a {
    color: #fff;
}

html.dark-mode .no-image .author-social-link a {
    color: rgba(255, 255, 255, 0.75);
}

html.dark-mode .gh-content > [id] {
    color: rgba(255, 255, 255, 0.9);
}

html.dark-mode .gh-content pre {
    background: color-mod(var(--color-darkgrey) l(-8%));
}

html.dark-mode .gh-content :not(pre) > code {
    background: color-mod(var(--color-darkgrey) l(+6%));
    border-color: color-mod(var(--color-darkmode) l(+8%));
    color: var(--color-wash);
}

:where(html.dark-mode) .gh-content a {
    color: #fff;
}

html.dark-mode .gh-content strong {
    color: #fff;
}

html.dark-mode .gh-content em {
    color: #fff;
}

html.dark-mode .gh-content code {
    color: #fff;
    background: #000;
}

html.dark-mode hr {
    border-top-color: color-mod(var(--color-darkmode) l(+8%));
}

html.dark-mode .gh-content hr:after {
    background: color-mod(var(--color-darkmode) l(+8%));
    box-shadow: var(--color-darkmode) 0 0 0 5px;
}

html.dark-mode figcaption {
    color: rgba(255, 255, 255, 0.6);
}

html.dark-mode .gh-content table:not(.gist table) td:first-child {
    background-image: linear-gradient(to right, var(--color-darkmode) 50%, color-mod(var(--color-darkmode) a(0%)) 100%);
}

html.dark-mode .gh-content table:not(.gist table) td:last-child {
    background-image: linear-gradient(to left, var(--color-darkmode) 50%, color-mod(var(--color-darkmode) a(0%)) 100%);
}

html.dark-mode .gh-content table:not(.gist table) th {
    color: rgba(255, 255, 255, 0.85);
    background-color: color-mod(var(--color-darkmode) l(+8%));
}

html.dark-mode .gh-content table:not(.gist table) th,
html.dark-mode .gh-content table:not(.gist table) td {
    border: color-mod(var(--color-darkmode) l(+8%)) 1px solid;
}

html.dark-mode .gh-content input {
    color: color-mod(var(--color-midgrey) l(-30%));
}

html.dark-mode .site-archive-header .no-image {
    color: rgba(255, 255, 255, 0.9);
    background: var(--color-darkmode);
}

html.dark-mode .kg-header-card.kg-style-dark {
    background: color-mod(var(--color-darkgrey) l(-5%));
}

html.dark-mode .kg-header-card.kg-style-light {
    background: color-mod(var(--color-darkgrey) l(+5%));
}

html.dark-mode .kg-header-card h2.kg-header-card-header,
html.dark-mode .kg-header-card h3.kg-header-card-subheader {
    color: #fff;
}

html.dark-mode .footer-cta-title {
    color: #fff;
}

@media (prefers-color-scheme: dark) {
    html.auto-color body {
        color: rgba(255, 255, 255, 0.75);
        background: var(--color-darkmode);
    }

    html.auto-color img {
        opacity: 0.9;
    }

    html.auto-color kbd {
        background: color-mod(var(--color-darkmode) l(+5%));
    }

    html.auto-color figcaption a {
        color: #fff;
    }

    html.auto-color .gh-head {
        background: var(--color-darkmode);
        color: #fff;
    }

    html.auto-color .gh-burger-box {
        color: #fff;
    }

    html.auto-color .site-header-content {
        color: #fff;
    }

    html.auto-color .post-card-image {
        background: var(--color-darkmode);
    }

    html.auto-color :is(.post-card-tags, .post-card-meta, .article-tag a, .byline-meta-content) {
        color: color-mod(var(--color-secondary-text) l(-22%));
    }

    html.auto-color .post-card-featured {
        color: #fff;
    }

    html.auto-color .post-card-title {
        color: #fff;
    }

    html.auto-color .post-card-excerpt {
        color: var(--color-secondary-text);
    }

    html.auto-color .author-profile-location {
        color: #fff;
    }

    html.auto-color .author-profile-social-link:hover {
        color: #fff;
    }

    html.auto-color .article-title {
        color: #fff;
    }

    html.auto-color .article-excerpt {
        color: var(--color-secondary-text);
    }

    html.auto-color .post-full-image {
        background-color: color-mod(var(--color-darkmode) l(+8%));
    }

    html.auto-color .author-avatar {
        border-color: var(--color-darkmode);
        background-color: color-mod(var(--color-darkmode) l(+8%));
    }

    html.auto-color .author-profile-image {
        opacity: 1;
    }

    html.auto-color .author-profile-image path {
        fill: var(--color-darkmode);
    }

    html.auto-color .article-byline-meta .author-name a {
        color: #fff;
    }

    html.auto-color .no-image .author-social-link a {
        color: rgba(255, 255, 255, 0.75);
    }

    html.auto-color .gh-content > [id] {
        color: rgba(255, 255, 255, 0.9);
    }

    html.auto-color .gh-content pre {
        background: color-mod(var(--color-darkgrey) l(-8%));
    }

    html.auto-color .gh-content :not(pre) > code {
        background: color-mod(var(--color-darkgrey) l(+6%));
        border-color: color-mod(var(--color-darkmode) l(+8%));
        color: var(--color-wash);
    }

    :where(html.auto-color) .gh-content a {
        color: #fff;
    }

    html.auto-color .gh-content strong {
        color: #fff;
    }

    html.auto-color .gh-content em {
        color: #fff;
    }

    html.auto-color .gh-content code {
        color: #fff;
        background: #000;
    }

    html.auto-color hr {
        border-top-color: color-mod(var(--color-darkmode) l(+8%));
    }

    html.auto-color .gh-content hr:after {
        background: color-mod(var(--color-darkmode) l(+8%));
        box-shadow: var(--color-darkmode) 0 0 0 5px;
    }

    html.auto-color figcaption {
        color: rgba(255, 255, 255, 0.6);
    }

    html.auto-color .gh-content table:not(.gist table) td:first-child {
        background-image: linear-gradient(to right, var(--color-darkmode) 50%, color-mod(var(--color-darkmode) a(0%)) 100%);
    }

    html.auto-color .gh-content table:not(.gist table) td:last-child {
        background-image: linear-gradient(to left, var(--color-darkmode) 50%, color-mod(var(--color-darkmode) a(0%)) 100%);
    }

    html.auto-color .gh-content table:not(.gist table) th {
        color: rgba(255, 255, 255, 0.85);
        background-color: color-mod(var(--color-darkmode) l(+8%));
    }

    html.auto-color .gh-content table:not(.gist table) th,
    html.auto-color .gh-content table:not(.gist table) td {
        border: color-mod(var(--color-darkmode) l(+8%)) 1px solid;
    }

    html.auto-color .gh-content input {
        color: color-mod(var(--color-midgrey) l(-30%));
    }

    html.auto-color .site-archive-header .no-image {
        color: rgba(255, 255, 255, 0.9);
        background: var(--color-darkmode);
    }

    html.auto-color .kg-header-card.kg-style-dark {
        background: color-mod(var(--color-darkgrey) l(-5%));
    }

    html.auto-color .kg-header-card.kg-style-light {
        background: color-mod(var(--color-darkgrey) l(+5%));
    }

    html.auto-color .kg-header-card h2.kg-header-card-header,
    html.auto-color .kg-header-card h3.kg-header-card-subheader {
        color: #fff;
    }

    html.auto-color .footer-cta-title {
        color: #fff;
    }
}

/*

Hey! You reached the end.

Hope you enjoyed this CSS file, if you have any suggestions
for improvements that might be useful for everyone who uses
this theme, you can find the open source repository for it
here: https://github.com/tryghost/casper

Or, if you've just scrolled all the way to the bottom of the
file to add some of your own styles. Well, you've come to
the right place. Onward!

 */
